<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
        <div>
          <div class="mt-3 sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              Confirm details below to complete your booth reservation
            </h3>
            <div class="mt-2">
              <div v-for="(item, index) in invoice.items" :key="index" class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">{{ item.description }}</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ item.price.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Subtotal</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ subtotal.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">HST</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ hst.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Invoice Total</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ total.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div v-if="paymentTotal() > 0" class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Amount Paid</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ paymentTotal().toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div v-if="balance() > 0 && paymentTotal() > 0" class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Remaining Balance</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ balance().toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Payment Today</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right font-bold">
                  $ {{ toPayRounded }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button v-if="!processing" @click="cont" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
            Complete Reservation
          </button>
          <div v-if="processing" class="w-full sm:col-start-2 text-base font-medium ">
            <font-awesome-icon :icon="['fad', 'spinner']" spin="spin"/> {{ processingMessage }}
          </div>
          <button @click="cancel" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Review",
  data() {
    return {
      member: 0,
      error: {},
    }
  },
  computed: {
    booth() {
      return this.$store.state.booth;
    },
    payment() {
      return this.$store.state.payment;
    },
    invoice() {
      return this.$store.state.invoice;
    },
    selectedExtras() {
      return this.$store.state.extras.filter(extra => extra.selected);
    },
    selectedSponsorships() {
      return this.$store.state.sponsorships.filter(sponsorship => sponsorship.selected);
    },
    subtotal() {
      let subtotal = 0;
      this.$store.state.invoice.items.forEach(item => {
        subtotal = subtotal + item.price;
      });
      return subtotal;
    },
    hst() {
      return this.subtotal * 0.13;
    },
    total() {
      return this.subtotal + this.hst;
    },
    toPay() {
      let extratotals = 0;
      this.$store.state.extras.filter(extra => extra.selected).forEach(extra => {
        extratotals = extratotals + extra.priceValue;
      });
      this.$store.state.sponsorships.filter(sponsorship => sponsorship.selected).forEach(sponsorship => {
        extratotals = extratotals + sponsorship.priceValue;
      });
      return (this.$store.state.payment + extratotals) * 1.13;
    },
    toPayRounded() {
      return this.toPay.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    },
    processing() {
      return this.$store.state.processing;
    },
    processingMessage() {
      return this.$store.state.processingMessage;
    },
    card() {
      return this.$store.state.card;
    },
    contact() {
      return this.$store.state.contact;
    },
    exhibitor() {
      if (this.$store.state.exhibitors && this.$store.state.exhibitors[0]) {
        return this.$store.state.exhibitors[0];
      }
      return {}
    },
    payments() {
      if (this.$store.state.exhibitorDetail && this.$store.state.exhibitorDetail.payments) {
        return this.$store.state.exhibitorDetail.payments;
      }
      return [];
    },
    invoices() {
      if (this.$store.state.exhibitorDetail && this.$store.state.exhibitorDetail.invoiceEditorViewModel && this.$store.state.exhibitorDetail.invoiceEditorViewModel.invoices) {
        return this.$store.state.exhibitorDetail.invoiceEditorViewModel.invoices;
      }
      return [];
    },
  },
  methods: {
    invoiceTotal() {
      if (this.invoices.length === 0) {
        return 0;
      }
      let subtotal = this.invoices.flatMap(invoice => invoice.items).map(item => item.price).reduce((prev, next) => prev + next);
      let taxIncl = subtotal * 1.13;
      return taxIncl.toFixed(2);
    },
    paymentTotal() {
      if (this.payments.length === 0) {
        return 0;
      }
      return this.payments.map(payment => payment.amount).reduce((prev, next) => prev + next);
    },
    balance() {
      return this.invoiceTotal() - this.paymentTotal();
    },
    cancel() {
      this.$parent.state = "payment";
    },
    cont() {
      this.$store.commit("updateProcessing", true);
      this.$store.dispatch("logTransaction").then(() => {
        // once we've logged our state to a blob in the database for debugging purposes, we can try processing the transaction
        this.$store.dispatch("processPayment", {
          amount: this.toPayRounded,
          email_address: this.contact.email_address,
          card_name: this.card.card_name,
          card_number: this.card.card_number,
          card_expiry: this.card.card_expiry,
          card_cvv2: this.card.card_cvv2,
          postal_code: this.card.postal_code,
          associationID: 1,
          projectID: 1074,
          merchantname: "Landscape Ontario Congress",
          merchanturl: "https://locongress.com",
          invoice: this.invoice,
        }).then((response) => {
          if (response.data && response.data.errors) {
            this.$store.commit("saveProcessorError", response.data.errors);
            this.$store.commit("updateProcessing", false);
            this.$parent.state = "payment";
          }
          if (response.data && response.data.state && response.data.state !== "success") {
            this.$store.commit("saveProcessorError", { message: "Transaction error: " + response.data.details.responsemessage });
            this.$store.commit("updateProcessing", false);
            this.$parent.state = "payment";
          }
          if (response.data && response.data.state && response.data.state === "success" && response.data.details) {
            // add payment to exhibitor in expofp
            let details = response.data.details;
            this.$store.dispatch("addPayment", {
              amount: parseFloat(details.amount),
              date: moment(details.stamp).format("YYYY-MM-DD"),
              description: "Card Info: " + details.firstdigit + "***********" + details.lastfourdigits + " Transaction ID: " + details.processorID + " Reference Number: " + details.referencenumber + " Response Code: " + details.responsecode + " Response Message: " + details.responsemessage,
              exhibitorId: this.exhibitor.id,
            }).then(() => {
              let exhibitor = {};
              exhibitor.id = this.exhibitor.id;
              exhibitor.name = this.exhibitor.name;
              exhibitor.privateEmail =  this.contact.email_address;
              exhibitor.contactName = this.contact.first_name + " " + this.contact.last_name;
              this.$store.dispatch("updateExhibitor", exhibitor).then(() => {
                // send invoice
                this.$store.dispatch("sendInvoice", {
                  exhibitorId: this.exhibitor.id,
                  email: this.contact.email_address,
                  invoice: this.invoice,
                }).then(() => {
                  this.$store.dispatch("changeBoothOnHold", {
                    exhibitorId: this.exhibitor.id,
                    boothId: this.booth.id,
                    isOnHold: false,
                  }).then(() => {
                    this.selectedExtras.forEach(extra => {
                      this.$store.dispatch("assignExtra", {
                        exhibitorId: this.exhibitor.id,
                        boothId: this.booth.id,
                        extraId: extra.id,
                        quantity: 1
                      });
                    });
                    this.selectedSponsorships.forEach(sponsorship => {
                      this.$store.dispatch("assignExtra", {
                        exhibitorId: this.exhibitor.id,
                        extraId: sponsorship.id,
                        quantity: 1
                      });
                    });
                    this.$parent.state = "complete";
                  });
                });
              });
            });
          }
        });
      });
    }
  },
  mounted() {
  }
}
</script>