<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
        <div>
          <div class="mt-3 sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              Please select any additional booth extras
            </h3>
            <div class="mt-2">
              <div class="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                <div class="pt-6 sm:pt-5">
                  <div role="group" aria-labelledby="label-extras">
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                      <div>
                        <div class="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email">
                          Booth Extras
                        </div>
                      </div>
                      <div class="mt-4 sm:mt-0 sm:col-span-2">
                        <div class="max-w-lg space-y-4">
                          <div v-for="extra in extras" :key="extra.id" class="relative flex items-start">
                            <div class="flex items-center h-5">
                              <input v-model="extra.selected" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                            </div>
                            <div class="ml-3 text-sm">
                              <label class="font-medium text-gray-700">{{ extra.label }}</label>
                              <p class="text-gray-500">$ {{ extra.price }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                <div class="pt-6 sm:pt-5">
                  <div role="group" aria-labelledby="label-extras">
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                      <div>
                        <div class="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email">
                          Sponsorship Opportunities
                        </div>
                      </div>
                      <div class="mt-4 sm:mt-0 sm:col-span-2">
                        <div class="max-w-lg space-y-4">
                          <div v-for="sponsorship in sponsorships" :key="sponsorship.id" class="relative flex items-start">
                            <div class="flex items-center h-5">
                              <input v-model="sponsorship.selected" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                            </div>
                            <div class="ml-3 text-sm">
                              <label class="font-medium text-gray-700">{{ sponsorship.label }}</label>
                              <p class="text-gray-500">$ {{ sponsorship.price }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button @click="cont" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
            Continue to Payment
          </button>
          <button @click="cancel" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Extras",
  data() {
    return {
      error: {},
    }
  },
  computed: {
    exhibitor() {
      if (this.$store.state.exhibitors && this.$store.state.exhibitors[0]) {
        return this.$store.state.exhibitors[0];
      }
      return {}
    },
    booth() {
      return this.$store.state.booth;
    },
    boothId() {
      return this.$store.state.rawBooth.id;
    },
    extras() {
      return this.$store.state.extras;
    },
    sponsorships() {
      return this.$store.state.sponsorships;
    },
    invoice() {
      return this.$store.state.invoice;
    },
    selectedExtras() {
      return this.$store.state.extras.filter(extra => extra.selected);
    },
    selectedSponsorships() {
      return this.$store.state.sponsorships.filter(sponsorship => sponsorship.selected);
    }
  },
  methods: {
    cancel() {
      this.$parent.state = "options";
    },
    cont() {
      if (this.selectedExtras.length > 0 || this.selectedSponsorships.length > 0) {
        let invoice = this.invoice;
        this.selectedExtras.forEach(extra => {
          invoice.items.push({
            boothId: this.boothId,
            description: this.booth.name + " - " + extra.label,
            extraId: extra.id,
            price: extra.priceValue,
          });
        });
        this.selectedSponsorships.forEach(sponsorship => {
          invoice.items.push({
            boothId: null,
            description: sponsorship.label,
            extraId: sponsorship.id,
            price: sponsorship.priceValue,
          });
        });
        this.$store.dispatch("updateInvoice", {
          exhibitorId: this.exhibitor.id,
          invoice: invoice
        });
      }
      this.$parent.state = "payment";
    },
  },
  mounted() {
    this.$store.dispatch("updateExtras", {
      exhibitorId: this.exhibitor.id,
      boothId: this.boothId,
    });
    this.$store.dispatch("updateSponsorships", {
      exhibitorId: this.exhibitor.id,
    });
  }
}
</script>