<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
        <div>
          <div class="mt-3 sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              Reservation complete
            </h3>
            <span>
              Check your email to find your invoice and payment summary, you may need to look in your spam/junk folder.
              The email will also include a link to set up your booth profile.
            </span>
            <p>
              Thank you for your reservation, we look forward to seeing you in person in January!
            </p>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
          <button @click="cancel" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-1 sm:text-sm">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Complete",
  data() {
    return {
      error: {},
    }
  },
  computed: {
    exhibitor() {
      if (this.$store.state.exhibitors && this.$store.state.exhibitors[0]) {
        return this.$store.state.exhibitors[0];
      }
      return {}
    },
    contact() {
      return this.$store.state.contact;
    }
  },
  methods: {
    cancel() {
      this.$parent.state = "cancel";
    }
  },
  mounted() {
  }
}
</script>
