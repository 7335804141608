<template>
  <div class="home">
    <FloorPlan/>
  </div>
</template>

<script>
import FloorPlan from "@/components/FloorPlan.vue";
export default {
  name: "Home",
  components: {
    FloorPlan
  },
};
</script>
