import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './assets/index.css';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faSpinner
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
