<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
        <div>
          <div class="mt-3 sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              Complete the payment form to complete your reservation
            </h3>
            <p v-if="Object.keys(error).length > 0" class="text-sm text-red-600">There was a problem with your payment details</p>
            <p v-if="error.message" class="text-sm text-red-600">{{ error.message }}</p>
            <div class="mt-2">
              <div class="col-span-6 sm:col-span-4">
                <label for="card_name" class="block text-sm font-medium leading-5 text-gray-700">Name on Card</label>
                <input v-model="card.card_name" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <p v-if="error.card_name" class="mt-2 text-sm text-red-600">Name on card must not be blank</p>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="card_number" class="block text-sm font-medium leading-5 text-gray-700">Credit Card Number</label>
                <input v-model="card.card_number" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <p v-if="error.card_number" class="mt-2 text-sm text-red-600">Card number must be valid</p>
              </div>
              <div class="col-span-3 sm:col-span-3">
                <label for="card_expiry" class="block text-sm font-medium leading-5 text-gray-700">Credit Card Expiry Date</label>
                <input v-model="card.card_expiry" placeholder="MMYY" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <p v-if="error.card_expiry" class="mt-2 text-sm text-red-600">Card expiration must be in the future</p>
              </div>
              <div class="col-span-3 sm:col-span-3">
                <label for="card_cvv2" class="block text-sm font-medium leading-5 text-gray-700">Card Verification Code</label>
                <input v-model="card.card_cvv2" placeholder="###" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <p v-if="error.card_cvv2" class="mt-2 text-sm text-red-600">Card verification code must be included</p>
              </div>
              <div class="col-span-6">
                <label for="street_address" class="block text-sm font-medium leading-5 text-gray-700">Street Address</label>
                <input v-model="card.street_address" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <p v-if="error.street_address" class="mt-2 text-sm text-red-600">Address must not be blank</p>
              </div>
              <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                <label for="city" class="block text-sm font-medium leading-5 text-gray-700">City</label>
                <input v-model="card.city" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <p v-if="error.city" class="mt-2 text-sm text-red-600">City must not be blank</p>
              </div>
              <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                <label for="province" class="block text-sm font-medium leading-5 text-gray-700">Province/State</label>
                <input v-model="card.province" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <p v-if="error.province" class="mt-2 text-sm text-red-600">Province must not be blank</p>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label for="postal_code" class="block text-sm font-medium leading-5 text-gray-700">Postal/ZIP Code</label>
                <input v-model="card.postal_code" placeholder="L1L 1L1" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <p v-if="error.postal_code" class="mt-2 text-sm text-red-600">Postal code must be valid</p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button @click="cont" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
            Review Reservation
          </button>
          <button @click="cancel" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
            Back
          </button>
        </div>
      </div>
    </div>
  </div></template>

<script>
export default {
  name: "Payment",
  data() {
    return {
    }
  },
  computed: {
    card() {
      return this.$store.state.card;
    },
    error() {
      return this.$store.state.processorError;
    }
  },
  methods: {
    cancel() {
      this.$parent.state = "extras";
    },
    cont() {
/*
      this.$store.commit("updateCard", {
        card_name: this.card_name,
        card_number: this.card_number,
        card_expiry: this.card_expiry,
        card_cvv2: this.card_cvv2,
        street_address: this.street_address,
        city: this.city,
        province: this.province,
        postal_code: this.postal_code,
      });
*/
      this.$parent.state = "review";
    }
  },
  mounted() {
  }
}
</script>