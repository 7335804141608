<template>
  <div class="w-full sm:flex">
    <SideBar :booth="booth"/>
    <iframe ref="floorplan" src="about:blank" class="w-screen h-screen">
      <div class="flex-initial"></div>
    </iframe>
  </div>
</template>

<script>
/* eslint-disable */
import SideBar from "@/components/SideBar.vue";
export default {
  name: "FloorPlan",
  components: {
    SideBar
  },
  data() {
    return {
      floorplan: null,
      booth: null,
    }
  },
  methods: {
    selectBooth(e) {
      this.booth = e.target;
      this.$store.commit("saveRawBooth", this.booth);
//      let layout = this.$refs.floorplan.firstChild.shadowRoot.firstChild.firstChild;
//      layout.firstChild.classList.remove("layout__fixed");
//      layout.firstChild.children[1].firstChild.src = "https://efp-data.s3.amazonaws.com/packages/master/expofp-overlay.png";
    }
  },
  mounted() {
    console.log(this.$refs.floorplan);
    let iframedoc = this.$refs.floorplan.contentWindow.document;
    let div = document.createElement("div");
    iframedoc.firstChild.lastChild.appendChild(div);
    this.floorplan = new ExpoFP.FloorPlan({
      element: div,
      eventId: "locongress24",
      dataUrl: "https://locongress24.expofp.com/data/",
      noOverlay: true,
      onBoothClick: e => {
        this.selectBooth(e);
      }
    });
  }
}
</script>