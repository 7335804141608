<template>
  <div class="sidebar w-full absolute sm:relative border-t border-black sm:border-t-0 bottom-0 sm:bottom-auto left-0 sm:left-auto sm:w-64 p-4 bg-gray-200 sm:bg-transparent">
    <h1 class="text-xl">Exhibitor Reservation Portal</h1>
    <p class="text-lg mt-4">Booth Details</p>
    <div v-if="booth">
      <div class="text-sm">
        Number: {{ booth.name }}
      </div>
      <div class="text-sm">
        Size: {{ booth.size }}
      </div>
      <div>
        <span v-if="booth.onHold" class="text-sm text-white rounded-full bg-red-500 px-3 py-1">
          On Hold
        </span>
      </div>
      <div v-if="loading" class="mt-2">
        <font-awesome-icon :icon="['fad', 'spinner']" spin />
        Loading...
      </div>
      <div v-if="!loading" class="mt-2">
        <div v-if="exhibitors.length">
          <div v-for="exhibitor in exhibitors" :key="exhibitor.id">
            <div class="pb-2">
              {{ exhibitor.name }}
            </div>
            <div v-if="exhibitor.member">
              <span class="text-sm text-white rounded-full bg-green-500 px-3 py-1">
                LO Member
              </span>
            </div>
            <div v-if="!exhibitor.member">
              <span class="text-sm text-white rounded-full bg-blue-500 px-3 py-1">
                Non Member
              </span>
            </div>
            <div class="pt-4">
              Is this your company?<br/>
              <button v-if="booth.onHold" @click="state = 'reserve'" class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Reserve your Booth!
              </button>
              <button v-if="!booth.onHold && (balance() > 0 || invoiceTotal() === 0)" @click="state = 'reserve'" class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Make Booth Payment
              </button>
            </div>
          </div>
        </div>
        <div v-if="exhibitors.length === 0">
          This booth hasn't been released yet, if you'd like to inquire please contact us at <a class="text-blue-500 underline" :href="'mailto:showinfo@locongress.com?subject=Interested+in+Booth+' + booth.name">showinfo@locongress.com</a>
        </div>
      </div>
    </div>
    <div v-if="!booth">
      Select a booth to view it's information
    </div>
    <Reserve v-if="state == 'reserve'"/>
    <Options v-if="state == 'options'"/>
    <Extras v-if="state == 'extras'"/>
    <Payment v-if="state == 'payment'"/>
    <Review v-if="state == 'review'"/>
    <Complete v-if="state == 'complete'"/>
    <div class="legend mt-2 sm:mt-16">
      <h2>Legend</h2>
      <div class="text-sm px-2 py-1 text-white" style="background-color: #3D6FC7">
        Booth On Hold
      </div>
      <div class="text-sm px-2 py-1 text-white" style="background-color: #41b6e7">
        Booth Reserved
      </div>
      <div class="text-sm px-2 py-1 text-white" style="background-color: #9AADAD">
        Booth Unassigned
      </div>
    </div>
  </div>
</template>

<script>
import Reserve from "@/components/Reserve.vue";
import Options from "@/components/Options.vue";
import Extras from "@/components/Extras.vue";
import Payment from "@/components/Payment.vue";
import Review from "@/components/Review.vue";
import Complete from "@/components/Complete.vue";
export default {
  name: "SideBar",
  components: {
    Reserve,
    Options,
    Extras,
    Payment,
    Review,
    Complete,
  },
  data() {
    return {
      state: "none",
    }
  },
  props: {
    booth: Object
  },
  computed: {
    exhibitors() {
      return this.$store.state.exhibitors;
    },
    loading() {
      return this.$store.state.loading;
    },
    payments() {
      if (this.$store.state.exhibitorDetail && this.$store.state.exhibitorDetail.payments) {
        return this.$store.state.exhibitorDetail.payments;
      }
      return [];
    },
    invoices() {
      if (this.$store.state.exhibitorDetail && this.$store.state.exhibitorDetail.invoiceEditorViewModel && this.$store.state.exhibitorDetail.invoiceEditorViewModel.invoices) {
        return this.$store.state.exhibitorDetail.invoiceEditorViewModel.invoices;
      }
      return [];
    },
  },
  methods: {
    invoiceTotal() {
      if (this.invoices.length === 0) {
        return 0;
      }
      let subtotal = this.invoices.flatMap(invoice => invoice.items).map(item => item.price).reduce((prev, next) => prev + next);
      let taxIncl = subtotal * 1.13;
      return taxIncl.toFixed(2);
    },
    paymentTotal() {
      if (this.payments.length === 0) {
        return 0;
      }
      return this.payments.map(payment => payment.amount).reduce((prev, next) => prev + next);
    },
    balance() {
      return this.invoiceTotal() - this.paymentTotal();
    }
  },
  watch: {
    booth(newBooth) {
      if (newBooth && newBooth.id) {
        // go get exhibitor details
        this.$store.dispatch("getBooth", newBooth);
      }
    }
  }
}
</script>