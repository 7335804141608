<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
        <div>
          <div class="mt-3 sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              Reserve your booth and set up your profile
            </h3>
            <div class="mt-2">
              <div class="col-span-6 sm:col-span-4">
                <label for="company_name" class="block text-sm font-medium leading-5 text-gray-700">Company Name</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  {{ exhibitor.name }}
                </div>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">First Name</label>
                <input v-model="contact.first_name" name="first_name" type="input" required="required" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <p v-if="error.first_name" class="mt-2 text-sm text-red-600">First name must not be blank</p>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700">Last Name</label>
                <input v-model="contact.last_name" name="last_name" type="input" required="required" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <p v-if="error.last_name" class="mt-2 text-sm text-red-600">Last name must not be blank</p>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="email_address" class="block text-sm font-medium leading-5 text-gray-700">Email Address</label>
                <input v-model="contact.email_address" type="email" name="email_address" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <p v-if="error.email_address" class="mt-2 text-sm text-red-600">Email address must be valid</p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button @click="cont" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
            Continue
          </button>
          <button @click="cancel" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reserve",
  data() {
    return {
      error: {},
    }
  },
  computed: {
    exhibitor() {
      if (this.$store.state.exhibitors && this.$store.state.exhibitors[0]) {
        return this.$store.state.exhibitors[0];
      }
      return {}
    },
    contact() {
      return this.$store.state.contact;
    }
  },
  methods: {
    cancel() {
      this.$parent.state = "cancel";
    },
    cont() {
/*
      this.$store.commit("updateContact", {
        company_name: this.company_name,
        first_name: this.first_name,
        last_name: this.last_name,
        email_address: this.email_address,
      })
*/
      this.$parent.state = "options";
    }
  },
  mounted() {
  }
}
</script>