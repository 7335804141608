import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";

Vue.use(Vuex);
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Accept"] = "application/json";

export default new Vuex.Store({
  state: {
    exhibitors: [],
    exhibitor: {},
    exhibitorDetail: {},
    booth: {},
    rawBooth: {},
    contact: {},
    invoice: {},
    payment: 0,
    card: {},
    amount: {},
    extras: [],
    sponsorships: [],
    processing: false,
    processingMessage: "",
    processor: {},
    processorError: {},
    loading: false,
  },
  getters: {
    cleanRawBooth(state) {
      return {
        availColor: state.rawBooth.availColor,
        buyUrl: state.rawBooth.buyUrl,
        description: state.rawBooth.description,
        error: state.rawBooth.error,
        holdColor: state.rawBooth.holdColor,
        id: state.rawBooth.id,
        name: state.rawBooth.name,
        noLabels: state.rawBooth.noLabels,
        onHold: state.rawBooth.onHold,
        paths: state.rawBooth.paths,
        pathsWithRect: state.rawBooth.pathsWithRect,
        price: state.rawBooth.price,
        rect: state.rawBooth.rect,
        reserveUrl: state.rawBooth.reserveUrl,
        reserved: state.rawBooth.reserved,
        rotate: state.rawBooth.rotate,
        size: state.rawBooth.size,
        slug: state.rawBooth.slug,
        soldColor: state.rawBooth.soldColor,
        status: state.rawBooth.status,
        title: state.rawBooth.title,
        type: state.rawBooth.type,
      }
    }
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setBooth(state, payload) {
      state.booth = payload;
    },
    addExhibitor(state, payload) {
      state.exhibitors.push(payload);
    },
    clearExhibitors(state) {
      state.exhibitors = [];
    },
    updateExhibitor(state, payload) {
      state.exhibitor = payload;
    },
    updateExhibitorDetail(state, payload) {
      state.exhibitorDetail = payload;
    },
    updateContactCompanyName(state, payload) {
      state.contact.company_name = payload;
    },
    updateContact(state, payload) {
      state.contact = payload;
    },
    updatePayment(state, payload) {
      state.payment = payload;
    },
    saveRawBooth(state, payload) {
      state.rawBooth = payload;
    },
    updateInvoices(state, payload) {
      if (payload.length > 0) {
        payload.forEach(invoice => {
          state.invoice = {
            number: invoice.number,
            date: moment(invoice.date).format("YYYY-MM-DD"),
            id: invoice.value
          };
        });
      }
      else {
        state.invoice = {};
      }
    },
    updateInvoice(state, payload) {
      if (payload.invoice) {
        state.invoice = payload.invoice;
      }
      else {
        state.invoice = {};
      }
    },
    updateInvoiceItems(state, payload) {
      if (payload.items && state.invoice) {
        state.invoice.items = payload.items;
      }
    },
    updateAmount(state, payload) {
      state.amount = payload;
    },
    updateExtras(state, payload) {
      state.extras = payload;
    },
    updateSponsorships(state, payload) {
      state.sponsorships = payload;
    },
    updateCard(state, payload) {
      state.card = payload;
    },
    saveProcessor(state, payload) {
      state.processor = payload;
    },
    saveProcessorError(state, payload) {
      state.processorError = payload;
    },
    updateProcessing(state, payload) {
      state.processing = payload;
    },
    setProcessingMessage(state, payload) {
      state.processingMessage = payload;
    }
  },
  actions: {
    getBooth(context, booth) {
      context.commit("setLoading", true);
      return axios.get("/booth/" + booth.name).then(response => {
        context.commit("clearExhibitors");
        context.commit("setBooth", booth);

        if (response.data && response.data.exhibitors && response.data.exhibitors.length > 0) {
          response.data.exhibitors.forEach(exhibitorId => {
            context.dispatch("getExhibitor", exhibitorId);
          });
        }
        else {
          context.commit("setLoading", false);
        }
      });
    },
    changeBoothOnHold(context, payload) {
      context.commit("setProcessingMessage", "Reserving Booth");
      return new Promise((resolve, reject) => {
        axios.post("/exhibitor/" + payload.exhibitorId + "/booth/" + payload.boothId + "/onhold", {
          boothId: payload.boothId,
          isOnHold: payload.isOnHold
        }).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getExhibitor(context, exhibitorId) {
      return axios.get("/exhibitor/" + exhibitorId).then(response => {
        context.commit("addExhibitor", response.data);
        context.commit("setLoading", false);
        context.dispatch("getExhibitorDetail", {
          exhibitorId: response.data.id
        });
      });
    },
    updateExhibitor(context, payload) {
      context.commit("setProcessingMessage", "Updating Contact Info");
      return new Promise((resolve, reject) => {
        axios.put("/exhibitor/" + payload.id, payload).then(response => {
          context.commit("updateExhibitor", response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getExhibitorDetail(context, payload) {
      context.commit("setLoading", true);
      return new Promise((resolve, reject) => {
        axios.get("/exhibitor/" + payload.exhibitorId + "/detail").then(response => {
          context.commit("updateExhibitorDetail", response.data);
          context.commit("setLoading", false);
          resolve(response.data);
        }).catch(error => {
          context.commit("setLoading", false);
          reject(error);
        });
      });
    },
    getInvoices(context, payload) {
      return axios.get("/exhibitor/" + payload.exhibitorId + "/invoice").then(response => {
        context.commit("updateInvoices", response.data);
      })
    },
    createInvoice(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post("/exhibitor/" + payload.exhibitorId + "/invoice", payload).then(response => {
          context.commit("updateInvoice", response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateInvoice(context, payload) {
      return new Promise((resolve, reject) => {
        axios.put("/exhibitor/" + payload.exhibitorId + "/invoice/" + payload.invoice.id, {
          invoice: payload.invoice
        }).then(response => {
          context.commit("updateInvoiceItems", response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    sendInvoice(context, payload) {
      context.commit("setProcessingMessage", "Sending Invoice");
      return new Promise((resolve, reject) => {
        axios.post("/exhibitor/" + payload.exhibitorId + "/invoice/" + payload.invoice.id + "/send", {
          email: payload.email,
          invoice: payload.invoice
        }).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateExtras(context, payload) {
      return axios.get("/exhibitor/" + payload.exhibitorId + "/booth/" + payload.boothId + "/extras").then(response => {
        context.commit("updateExtras", response.data);
      })
    },
    updateSponsorships(context, payload) {
      return axios.get("/exhibitor/" + payload.exhibitorId + "/extras").then(response => {
        context.commit("updateSponsorships", response.data);
      })
    },
    assignExtra(context, payload) {
      context.commit("setProcessingMessage", "Assigning Extra");
      return new Promise((resolve, reject) => {
        axios.post("/exhibitor/" + payload.exhibitorId + "/extras", payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    logTransaction(context) {
      context.commit("setProcessingMessage", "Logging Details");
      return axios.post("/blob", {
        exhibitors: context.state.exhibitors,
        booth: context.getters.cleanRawBooth,
        contact: context.state.contact,
        invoice: context.state.invoice,
        payment: context.state.payment,
        card: context.state.card,
        amount: context.state.amount,
        extras: context.state.extras,
        sponsorships: context.state.sponsorships,
        processing: context.state.processing,
        processor: context.state.processor,
        exhibitorDetail: context.state.exhibitorDetail,
      });
    },
    processPayment(context, payload) {
      context.commit("setProcessingMessage", "Processing Payment");
      return new Promise((resolve, reject) => {
        axios.post("/processor", payload).then(response => {
          context.commit("saveProcessor", response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    addPayment(context, payload) {
      context.commit("setProcessingMessage", "Saving Payment");
      return new Promise((resolve, reject) => {
        axios.post("/exhibitor/" + payload.exhibitorId + "/payment", payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    }
  },
  modules: {},
});
