<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
        <div>
          <div class="mt-3 sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              Selected booth details
            </h3>
            <span>
              If you'd like to change booth sizes, please
              <a href="mailto:showinfo@locongress.com" target="_blank" class="underline text-blue-600">contact us</a>.
            </span>
            <div class="mt-2">
              <div class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Booth Number</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  {{ booth.name }}
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Booth Size</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  {{ booth.size }}
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Square Footage Rate</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ rate.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Square Footage Rate</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ cost.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">LO Member Discount (less $2 per sq.ft.)</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ discount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Sub-total</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ subtotal.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">HST</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ hst.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Total</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ total.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Minimum 20% Deposit</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ deposit.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div v-if="paymentTotal() > 0" class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Amount Paid</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ paymentTotal().toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div v-if="balance() > 0 && paymentTotal() > 0" class="col-span-6 sm:col-span-4">
                <label for="boothnumber" class="block text-sm font-medium leading-5 text-gray-700">Remaining Balance</label>
                <div class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-right">
                  $ {{ balance().toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="paymentTotal() === 0" class="mt-5 sm:mt-6 sm:grid sm:grid-cols-3 sm:gap-3 sm:grid-flow-row-dense">
          <button @click="payFull" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-3 sm:text-sm">
            Pay Full Amount
          </button>
          <button @click="payDeposit" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
            Pay 20% Deposit
          </button>
          <button @click="cancel" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
            Back
          </button>
        </div>
        <div v-if="paymentTotal() > 0" class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button @click="payRemaining" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
            Pay Remaining Balance
          </button>
          <button @click="cancel" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Options",
  data() {
    return {
      member: 0,
      company_name: "",
      first_name: "",
      last_name: "",
      email_address: "",
      error: {},
    }
  },
  computed: {
    booth() {
      return this.$store.state.booth;
    },
    sqft() {
      if (this.booth?.size) {
        return parseInt(this.booth.size.split(" ")[4]);
      }
      return 0;
    },
    rate() {
      if (this.booth?.type) {
        if (this.booth.type == "Up to 400 square feet (Standard Pricing)") {
          return 20.95;
        }
        if (this.booth.type == "Between 401-999 square feet (Standard Pricing)") {
          return 20.70;
        }
        if (this.booth.type == "Over 1,000 square feet (Standard Pricing)") {
          return 20.45;
        }
        if (this.booth.type == "Up to 400 square feet (Early Bird Pricing)") {
          return 20.35;
        }
        if (this.booth.type == "Between 401-999 square feet (Early Bird pricing)") {
          return 20.10;
        }
        if (this.booth.type == "Over 1,000 square feet (Early Bird Pricing)") {
          return 19.85;
        }
        return 20.45;
      }
      return 20.45;
    },
    rateType() {
      if (this.booth?.type) {
        return this.booth.type;
      }
      return "Up to 400 square feet (Deadline September 15)";
    },
    cost() {
      return this.rate * this.sqft;
    },
    exhibitor() {
      if (this.$store.state.exhibitors && this.$store.state.exhibitors[0]) {
        return this.$store.state.exhibitors[0];
      }
      return {}
    },
    discount() {
      if (this.$store.state.exhibitors && this.$store.state.exhibitors[0] && this.$store.state.exhibitors[0].member) {
        return this.sqft * 2;
      }
      return 0;
    },
    subtotal() {
      return this.cost - this.discount;
    },
    hst() {
      return this.subtotal * 0.13;
    },
    total() {
      return this.subtotal + this.hst;
    },
    deposit() {
      return this.total * .2;
    },
    boothId() {
      return this.$store.state.rawBooth.id;
    },
    invoice() {
      return this.$store.state.invoice;
    },
    payments() {
      if (this.$store.state.exhibitorDetail && this.$store.state.exhibitorDetail.payments) {
        return this.$store.state.exhibitorDetail.payments;
      }
      return [];
    },
    invoices() {
      if (this.$store.state.exhibitorDetail && this.$store.state.exhibitorDetail.invoiceEditorViewModel && this.$store.state.exhibitorDetail.invoiceEditorViewModel.invoices) {
        return this.$store.state.exhibitorDetail.invoiceEditorViewModel.invoices;
      }
      return [];
    },
  },
  methods: {
    invoiceTotal() {
      if (this.invoices.length === 0) {
        return 0;
      }
      let subtotal = this.invoices.flatMap(invoice => invoice.items).map(item => item.price).reduce((prev, next) => prev + next);
      let taxIncl = subtotal * 1.13;
      return taxIncl.toFixed(2);
    },
    paymentTotal() {
      if (this.payments.length === 0) {
        return 0;
      }
      return this.payments.map(payment => payment.amount).reduce((prev, next) => prev + next);
    },
    balance() {
      return this.invoiceTotal() - this.paymentTotal();
    },
    cancel() {
      this.$parent.state = "reserve";
    },
    cont() {
      if (this.invoice && this.invoice.id) {
        // there's an existing invoice
        let invoice = this.invoice;
        if (!invoice.items) {
          invoice.items = [];
        }
        invoice.items.push({
          price: this.cost,
          description: "Booth " + this.booth.name + " - " + this.rateType + " / " + this.booth.size,
          boothId: this.boothId,
        });
        invoice.items.push({
          description: "LO Member Discount (less $2 per sq.ft.)",
          price: -1 * this.discount
        });
        this.$store.dispatch("updateInvoice", {
          exhibitorId: this.exhibitor.id,
          invoice: invoice,
        })
      }
      else {
        this.$store.dispatch("createInvoice", {
          exhibitorId: this.exhibitor.id,
          item: {
            price: this.cost,
            description: "Booth " + this.booth.name + " - " + this.rateType + " / " + this.booth.size,
            boothId: this.boothId,
          }
        }).then(response => {
          if (this.discount > 0) {
            let invoice = response.data.invoice;
            invoice.items.push({
              description: "LO Member Discount (less $2 per sq.ft.)",
              price: -1 * this.discount
            });
            this.$store.dispatch("updateInvoice", {
              exhibitorId: this.exhibitor.id,
              invoice: invoice
            });
          }
        });
      }
      this.$store.commit("updateAmount", {
        sqft: this.sqft,
        rate: this.rate,
        rateType: this.rateType,
        cost: this.cost,
        discount: this.discount,
        subtotal: this.subtotal,
        hst: this.hst,
        total: this.total,
        deposit: this.deposit,
      });
      this.$parent.state = "extras";
    },
    payFull() {
      this.cont();
      this.$store.commit("updatePayment", this.subtotal);
    },
    payDeposit() {
      this.cont();
      this.$store.commit("updatePayment", (this.subtotal * .2));
    },
    payRemaining() {
      this.cont();
      this.$store.commit("updatePayment", (this.balance() / 1.13));
    }
  },
  mounted() {
    this.$store.dispatch("getInvoices", {
      exhibitorId: this.exhibitor.id
    });
  }
}
</script>